
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { Form, FormActions } from "vee-validate";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import {
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadVue3
} from "@progress/kendo-vue-upload";
import { CancellationOfferImageModel } from "@/models";
import { useStore, AppGetterTypes } from "@/store";
import { AppActionTypes, restClient } from "@/store/actions";
import {
    createImageFormData,
    createUniqueUrl,
    promptDelete,
    imageUploadFileRestrictions,
    IMAGE_TOOLTIP_MESSAGE
} from "@/helpers";

import UploadPreview from "./UploadPreview.vue";
import InformationTooltipVue from "./InformationTooltip.vue";
import HtmlEditor from "./HtmlEditor.vue";

export default defineComponent({
    props: {
        image: {
            type: Object as PropType<CancellationOfferImageModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        offerId: {
            type: Number,
            required: true
        },

        /** ID for a grid to refresh after an add/edit is made */
        refreshGridId: {
            type: String,
            required: false,
            default: ""
        }
    },
    emits: ["handleClose"],
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "upload": UploadVue3,
        "upload-preview": UploadPreview,
        "information-tooltip": InformationTooltipVue,
        "html-editor": HtmlEditor
    },
    setup(props, { emit }) {
        const store = useStore();
        const model: CancellationOfferImageModel = reactive(props.image);
        const rules = computed(
            () =>
                store.getters[AppGetterTypes.validationRules]
                    ?.cancellationOfferImageModel
        );

        const isNew = model.id === 0;
        // Image upload
        const imageFile = ref<UploadFileInfo>();
        const previewSource = ref<string>();
        const imageHasChanged = ref(false);

        // Save/create offer image
        const onSubmit = async (
            values: CancellationOfferImageModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            model.cancellationOfferId = props.offerId;

            if (isNew && !imageFile.value) {
                imageHasChanged.value = true;
            }

            // If the image has changed, ensure it is valid and present
            if (
                (!imageFile.value && imageHasChanged.value) ||
                imageFile.value?.validationErrors
            ) {
                return;
            }

            // Set sort order to null if it has been removed
            if (!model.sortOrder) {
                model.sortOrder = null;
            }

            // Save data
            const response = isNew
                ? await restClient.postJson<CancellationOfferImageModel>(
                      "/api/admin/CancellationOfferImage",
                      model
                  )
                : await restClient.putJson<CancellationOfferImageModel>(
                      "/api/admin/CancellationOfferImage",
                      model
                  );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // If the image has changed and there are no errors, upload the new one
            if (imageHasChanged.value && imageFile.value) {
                const formData = createImageFormData(imageFile.value);

                // Save image
                const imageUploadResponse = await restClient.postJson(
                    `/api/admin/CancellationOfferImageUpload?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(imageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }

            // Refresh a grid on the outside of this component after an add/edit happened
            if (props.refreshGridId) {
                await store.dispatch(
                    AppActionTypes.refreshGrid,
                    props.refreshGridId
                );
            }

            emit("handleClose");
        };

        // Prompt user to delete image
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete("Are you sure you want to delete the image?")
            ) {
                const response = await restClient.delete(
                    `/api/admin/CancellationOfferImageGrid/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                emit("handleClose");
            }
        };

        // Emit event to close modal on cancel
        const handleCancel = (): void => {
            emit("handleClose");
        };

        // Add image to upload component and build preview
        const addImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            imageFile.value = newImage;
            imageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        previewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove new image from upload component
        const removeImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete this image?"
                )
            ) {
                imageFile.value = event.newState[0];
                previewSource.value = undefined;
            }
        };

        return {
            onSubmit,
            handleDelete,
            handleCancel,
            model,
            rules,
            isNew,
            addImage,
            removeImage,
            imageFile,
            previewSource,
            imageHasChanged,
            imageUploadFileRestrictions,
            createUniqueUrl,
            IMAGE_TOOLTIP_MESSAGE
        };
    }
});
