import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!

  return (_openBlock(), _createBlock(_component_editor, {
    "api-key": _ctx.tinyMceApiKey,
    plugins: ['code'],
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    init: _ctx.options
  }, null, 8, ["api-key", "modelValue", "init"]))
}