
import {
    computed,
    defineComponent,
    PropType,
    reactive,
    onMounted,
    watch,
    ref
} from "vue";
import { Form, FormActions } from "vee-validate";
import {
    getModelStateFromResponse,
    hasErrors,
    IValidationResponse,
    ValFormInput
} from "@elite/validation";
import CurrencyInput from "@/components/CurrencyInput.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import AlertMessage from "./AlertMessage.vue";
import HtmlEditor from "./HtmlEditor.vue";
import { CancellationOfferModel } from "@/models";
import { useStore, AppGetterTypes } from "@/store";
import { useRouter } from "vue-router";
import { routeNames, routePaths } from "@/router";
import { restClient } from "@/store/actions";

type CampaignCodes = {
    disabled: boolean;
    group: string;
    selected: boolean;
    text: string;
    value: string;
}[];
type CampaignOptions = {
    text: string;
    value: unknown;
}[];
export default defineComponent({
    props: {
        cancellationOffer: {
            type: Object as PropType<CancellationOfferModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        }
    },
    emits: ["handleClose", "changeId"],
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "html-editor": HtmlEditor,
        "currency-input": CurrencyInput,
        "loading-indicator": LoadingIndicator,
        "alert-message": AlertMessage
    },
    setup(props) {
        const router = useRouter();
        const { state, getters } = useStore();
        const campaignCodesList: CampaignOptions = reactive([]);
        const promotionCodesList: CampaignOptions = reactive([]);
        const model: CancellationOfferModel = reactive(props.cancellationOffer);
        const selectedCampaign = ref(model.campaignCode);
        const previewAction = ref(false);
        const saveAndContinue = ref(false);
        const showSaveConfirmation = ref(false);

        const rules = computed(
            () =>
                getters[AppGetterTypes.validationRules]?.cancellationOfferModel
        );

        const isNew = model.id === 0;
        const cancellationOfferCoursePath = {
            name: routeNames.editCourse,
            params: { courseId: props.courseId }
        };

        //Refresh the dropdowns when is a new offer
        watch(selectedCampaign, (newValue, oldValue): void => {
            getPromotionCodes().then((result: CampaignCodes) => {
                promotionCodesList.length = 0;
                result.forEach((item) => {
                    promotionCodesList.push({
                        text: item.value,
                        value: item.value
                    });
                });
            });
        });

        //Refresh the dropdowns when editing
        watch(campaignCodesList, (newValue, oldValue): void => {
            getPromotionCodes().then((result: CampaignCodes) => {
                promotionCodesList.length = 0;
                result.forEach((item) => {
                    promotionCodesList.push({
                        text: item.value,
                        value: item.value
                    });
                });
            });
        });

        const getCampaignCodes: () => Promise<CampaignCodes> = async () => {
            const campaignCodesResponse =
                await restClient.getJson<CampaignCodes>(
                    "/api/CampaignCodeItem"
                );
            if (campaignCodesResponse.data) {
                return campaignCodesResponse.data;
            }
            return [];
        };

        const getPromotionCodes: () => Promise<CampaignCodes> = async () => {
            const promotionCodesResponse =
                await restClient.getJson<CampaignCodes>(
                    "/api/PromotionCodeItem?campaignCode=" + model.campaignCode
                );
            if (promotionCodesResponse.data) {
                return promotionCodesResponse.data;
            }
            return [];
        };

        onMounted(() => {
            getCampaignCodes().then((result) => {
                result.forEach((item) => {
                    campaignCodesList.push({
                        text: item.value,
                        value: item.value
                    });
                });
            });
        });

        //Show the preview in a new tab
        const openPreview = (): void => {
            const cancellationOfferPreviewPath = router.resolve({
                name: routeNames.cancellationOfferPreview,
                params: { offerId: model.id }
            });
            window.open(cancellationOfferPreviewPath.href, "_blank");

            previewAction.value = false;
        };

        const onSubmit = async (
            values: CancellationOfferModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            const response = await saveOffer();
            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }
        };

        const saveOffer = async (): Promise<IValidationResponse> => {
            const create = model.id && model.id > 0 ? false : true;
            if (!model.minimumPrice) model.minimumPrice = 0;
            const response = create
                ? await restClient.postJson<CancellationOfferModel>(
                      "/api/admin/CancellationOffer",
                      model
                  )
                : await restClient.putJson<CancellationOfferModel>(
                      "/api/admin/CancellationOffer",
                      model
                  );

            if (!hasErrors(response)) {
                model.id = response.data?.id;
                const editCoursePath = {
                    name: routeNames.editCourse,
                    params: { courseId: props.courseId }
                };
                const editCancellationOfferPath = {
                    name: routeNames.editCancellationOffer,
                    params: {
                        courseId: props.courseId,
                        offerId: response.data?.id
                    }
                };

                //If we are showing a preview and saving a new offer we navigate to edit page
                if (previewAction.value) {
                    openPreview();

                    if (create) {
                        router.push(editCancellationOfferPath);
                        return response;
                    }
                }

                //If we are not showing a preview and saving a new offer we navigate to edit offer page
                if (create) {
                    router.push(editCancellationOfferPath);
                    return response;
                }

                //If we are not showing a preview and editing an offer we navigate to edit course page
                if (!create) {
                    if (!saveAndContinue.value) {
                        router.push(editCoursePath);
                    } else {
                        showSaveConfirmation.value = true;
                    }

                    saveAndContinue.value = false;
                    return response;
                }
            }
            return response;
        };

        const fontSizeItems = state.fontSizes.map((size) => ({
            text: size,
            value: size
        }));

        return {
            onSubmit,
            model,
            rules,
            isNew,
            routePaths,
            cancellationOfferCoursePath,
            campaignCodesList,
            promotionCodesList,
            selectedCampaign,
            previewAction,
            saveAndContinue,
            showSaveConfirmation,
            fontSizeItems
        };
    }
});
