
import { defineComponent, onMounted, PropType, ref } from "vue";

/**
 * One of the bootstrap alert variants: https://getbootstrap.com/docs/5.1/components/alerts/
 */
export type AlertVariant = "success" | "warning" | "danger" | "info";

export default defineComponent({
    props: {
        variant: {
            type: Object as PropType<AlertVariant>,
            required: false,
            default: () => "success" as unknown as object
        }
    },
    setup() {
        const alert = ref<HTMLDivElement | null>(null);

        onMounted(() => {
            // scroll to the alert div to make sure the user can see this message
            alert.value?.scrollIntoView({
                behavior: "smooth"
            });
        });

        return { alert };
    }
});
