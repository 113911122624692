
import { computed, defineComponent } from "vue";

import Editor from "@tinymce/tinymce-vue";
import { useStore } from "@/store";

export default defineComponent({
    components: { editor: Editor },
    props: {
        modelValue: {
            type: String,
            required: true
        }
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const tinyMceApiKey = process.env.VUE_APP_TINYMCE_API_KEY;

        const { state } = useStore();

        const options = {
            // see https://www.tiny.cloud/docs-4x/configure/content-formatting/#fontsize_formats
            toolbar:
                "undo redo | styleselect | bold italic | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent",
            fontsize_formats: state.fontSizes.join(" ")
        };

        const value = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                emit("update:modelValue", value);
            }
        });

        return { tinyMceApiKey, options, value };
    }
});
