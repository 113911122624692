
import { defineComponent, onMounted, ref, computed, reactive } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import CancellationOfferForm from "@/components/CancellationOfferForm.vue";
import CancellationOfferImageForm from "@/components/CancellationOfferImageForm.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ApiGrid from "@/components/ApiGrid.vue";
import { DialogVue3 } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";
import { cancellationOfferImageColumns } from "@/columns";
import {
    CancellationOfferModel,
    CancellationOfferImageModel,
    CancellationOfferImageGridModel
} from "@/models";
import { restClient } from "@/store/actions";
import { useRouter } from "vue-router";
import { routePaths } from "@/router";
import { routeNames } from "@/router/routeNames";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "cancellation-offer-form": CancellationOfferForm,
        "cancellation-offer-image-form": CancellationOfferImageForm,
        "loading-indicator": LoadingIndicator,
        "api-grid": ApiGrid,
        "k-dialog": DialogVue3
    },
    props: {
        courseId: { type: Number, required: true },
        offerId: { type: Number, required: true }
    },
    setup(props) {
        const router = useRouter();
        const model = ref<CancellationOfferModel>({});

        const gridId = computed(
            () => `cancellation-offer-image-grid-${props.offerId}`
        );

        const requestBody = reactive<CancellationOfferImageGridModel>({
            cancellationOfferId: props.offerId
        });

        if (!props.courseId) {
            router.push(routePaths.courseManagementPath);
        }

        const image = ref<CancellationOfferImageModel | undefined>();
        const visibleDialog = ref(false);
        const toggleDialog = (): void => {
            visibleDialog.value = !visibleDialog.value;
        };
        const modalTitle = computed(() =>
            image.value?.id && image.value.id > 0
                ? "Edit Cancel/Save Offer Image"
                : "Add Cancel/Save Offer Image"
        );

        const handleCreate = (): void => {
            requestBody.cancellationOfferId = props.offerId;
            image.value = undefined;
            toggleDialog();
            image.value = {
                id: 0,
                sortOrder: null,
                cancellationOfferId: 0,
                imageUrl: " "
            };
        };

        const handleEdit = async (id: number): Promise<void> => {
            image.value = undefined;

            toggleDialog();

            const response =
                await restClient.getJson<CancellationOfferImageModel>(
                    `/api/admin/CancellationOfferImage/${id}`
                );

            if (response.status !== 200) {
                toggleDialog();
            } else {
                image.value = response.data;
            }
        };

        onMounted(async () => {
            if (props.offerId === 0) {
                model.value = {
                    id: 0,
                    name: null,
                    description: "",
                    yesButtonColor: "",
                    yesButtonText: "",
                    noButtonColor: "",
                    noButtonText: "",
                    campaignCode: null,
                    promotionCode: null,
                    headerText: "",
                    courseId: props.courseId
                };
            } else {
                const response =
                    await restClient.getJson<CancellationOfferModel>(
                        `/api/admin/CancellationOffer/${props.offerId}`
                    );
                if (response.data) {
                    model.value = response.data;
                    requestBody.cancellationOfferId = model.value.id;
                }
            }
        });

        return {
            model,
            routeNames,
            cancellationOfferImageColumns,
            gridId,
            modalTitle,
            visibleDialog,
            image,
            handleCreate,
            handleEdit,
            toggleDialog,
            requestBody
        };
    }
});
