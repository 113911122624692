<template>
    <input ref="inputRef" type="text" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: "CurrencyInput",
    props: {
        // eslint-disable-next-line vue/require-default-prop
        modelValue: Number,
        // eslint-disable-next-line vue/require-default-prop
        options: Object
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    setup(props) {
        const { inputRef } = useCurrencyInput(props.options);

        return { inputRef };
    }
};
</script>
